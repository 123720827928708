import _ from 'lodash';
import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IRootStore } from '../store';

import { ApplicationVM } from './vm/application.vm';
import { CentralFeedVM } from './vm/central-feed.vm';
import { CommentsVM } from './vm/comments.vm';
import { EventsVM } from './vm/events.vm';
import { FeedVM } from './vm/feed.vm';
import { FilesVM } from './vm/files.vm';
import { GroupVM } from './vm/group.vm';
import { GroupsVM } from './vm/groups.vm';
import { MediaVM } from './vm/media.vm';
import { MembersVM } from './vm/members.vm';
import { TopicsVM } from './vm/topics.vm';
import { GroupRequestsVM } from './vm/group-requests.vm';
import { PricingPlansVM } from './vm/pricing-plans.vm';

import type { IViewModel } from './types';

export async function initializeViewModel(
  router: UIRouter,
  store: IRootStore,
  params: ControllerParams,
): Promise<IViewModel> {
  const comments$ = await CommentsVM(params, store);
  const application$ = await ApplicationVM(params, store);
  const centralFeed$ = CentralFeedVM(params, comments$, store);
  const events$ = EventsVM(params, store);
  const feed$ = FeedVM(params, comments$, store);
  const files$ = FilesVM(params, store);
  const group$ = GroupVM(params, comments$, store, router);
  const groups$ = GroupsVM(params, store);
  const media$ = MediaVM(params, store);
  const members$ = await MembersVM(params, store);
  const topics$ = TopicsVM(params, store);
  const groupRequests$ = GroupRequestsVM(params, store);
  const pricingPlans$ = PricingPlansVM(params, store);

  return _.merge(
    comments$,
    application$,
    centralFeed$,
    events$,
    feed$,
    files$,
    group$,
    groups$,
    media$,
    members$,
    topics$,
    groupRequests$,
    pricingPlans$,
  );
}

export function bindViewModel(
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const { setProps } = params.flowAPI.controllerConfig;

  setProps({
    ..._.omit(vm, '_'),
    fitToContentHeight: true,
    store: store.getState(),
  });

  store.subscribe(() => setProps({ store: store.getState() }));
}
